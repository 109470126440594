import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, locale, altLocale, description, image, imageSquared, imageAlt, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          siteUrl,
          author,
          defaultImage,
          defaultImageSquared,
          defaultImageAlt,
          twitterUsername,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        imageSquared: `${siteUrl}${imageSquared || defaultImageSquared}`,
        imageAltTag: imageAlt || defaultImageAlt,
        defaultUrl: `${siteUrl}`,
        author: `${author}`,
        url: `${siteUrl}/${locale}${pathname || "/"}`,
        alternateUrl: `${siteUrl}/${altLocale}${pathname || "/"}`,
      }

      return (
        <>
          <Helmet title={seo.title}>
            {/* Pre-connect to third-party domains and subdomains to improve website performance */}
            {/* Metomic */}
            <link rel="preconnect" href="https://apipub.metomic.io" crossorigin />
            <link rel="preconnect" href="https://config.metomic.io" />
            <link rel="preconnect" href="https://consent-manager.metomic.io" />
            {/* Gist */}
            <link rel="preconnect" href="https://data.getgist.com" crossorigin />
            <link rel="preconnect" href="https://messenger.getgist.com" crossorigin />
            <link rel="preconnect" href="https://widget.getgist.com" crossorigin />
            {/* Google */}
            <link rel="preconnect" href="https://www.googleadservices.com" crossorigin />
            <link rel="preconnect" href="https://googleads.g.doubleclick.net" crossorigin />
            <link rel="preconnect" href="https://www.google.com" crossorigin />
            <link rel="preconnect" href="https://www.google.ca" crossorigin />
            <link rel="preconnect" href="https://www.googletagmanager.com" crossorigin />
            <link rel="preconnect" href="https://ajax.googleapis.com" crossorigin />
            {/* Viral Loops */}
            <link rel="preconnect" href="https://app.viral-loops.com" crossorigin />
            {/* AWS S3 */}
            <link rel="preconnect" href="https://pages-static.s3.eu-west-1.amazonaws.com" crossorigin />

            {/* Locale Tags */}
            <html lang={locale} />
            <link rel="alternate" hreflang={locale} href={seo.url} />
            {locale === "en" ? (
              <link rel="alternate" hreflang="pt" href={seo.alternateUrl} />
            ) : (
              <link rel="alternate" hreflang="en" href={seo.alternateUrl} />
            )}
            <link rel="alternate" hreflang="x-default" href={seo.url} />

            {/* General Meta Tags */}
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="rating" content="General" />
            <meta name="author" content={seo.author} />

            {/* Google and Search Bots Meta Tags */}
            <meta name="robots" content="index,follow" />
            <meta name="googlebot" content="index,follow" />
            <meta name="google" content="nositelinkssearchbox" />

            {/* Favicon configs */}
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <meta name="application-name" content="Jubarte Design" />
            <meta name="theme-color" content="#ffffff" />

            {/* Android configs */}
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="icon" type="image/webp" sizes="192x192" href="/android-chrome-192x192.webp" />
            <meta name="mobile-web-app-capable" content="yes" />

            {/* Apple and iOS configs */}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#003849" />
            <meta name="apple-mobile-web-app-title" content="Jubarte Design" />
            <meta name="apple-mobile-web-app-capable" content="yes" />

            {/* Microsoft Icons' configs */}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/mstile-144x144.png" />

            {/* Open Graph Meta Tags */}
            <meta property="og:site_name" content="Jubarte Design" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {article ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="626" />
            {seo.image && <meta property="og:image" content={seo.imageSquared} />}
            <meta property="og:image:width" content="628" />
            <meta property="og:image:height" content="628" />
            {seo.imageAltTag && <meta property="og:image:alt" content={seo.imageAltTag} />}
            {locale === "en" ? (
              <meta property="og:locale" content="en_CA" />
            ) : (
              <meta property="og:locale" content="pt_BR" />
            )}

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {seo.imageAltTag && <meta name="twitter:image:alt" content={seo.imageAltTag} />}
            {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  locale: PropTypes.string,
  altLocale: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  imageSquared: PropTypes.string,
  imageAlt: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  locale: null,
  altLocale: null,
  description: null,
  author: null,
  image: null,
  imageSquared: null,
  imageAlt: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        author
        defaultImage: image
        defaultImageSquared: imageSquared
        defaultImageAlt: imageAlt
        twitterUsername
      }
    }
  }
`
