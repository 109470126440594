import React, { Component } from "react"
import { Link } from "gatsby"

export default class LanguageSelector extends Component {
  /* eslint-disable */
  constructor(props) {
    super(props)
    this.state = {
      path: "",
    }
  }

  state = { path: "" }

  componentDidMount() {
    this.setState({ path: window.location.pathname })
  }

  render() {
    return (
      <ul className="jd-languages">
        <li className="nav-item jd-navbar-link">
          <Link
            to={this.state.path.match("/en/") ? this.state.path.replace("/en/", "/pt/") : "/pt/"}
            className={
              "nav-link jd-header-nav-link jd-navbar-link-language" +
              (this.state.path.match("/pt/") || this.state.path === "/" ? " jd-active" : "")
            }
          >
            PT
          </Link>
        </li>
        <li className="nav-item jd-navbar-link">
          <span>|</span>
        </li>
        <li className="nav-item jd-navbar-link active">
          <Link
            to={this.state.path.match("/pt/") ? this.state.path.replace("/pt/", "/en/") : "/en/"}
            className={
              "nav-link jd-header-nav-link jd-navbar-link-language" +
              (this.state.path.match("/en/") ? " jd-active" : "")
            }
          >
            EN
          </Link>
        </li>
      </ul>
    )
  }
}
