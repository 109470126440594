/*
  SPLASH SCREENS FOR iOS.

  If the splash screen is missing or incorrect, a white screen will show on load, which looks unprofessional and makes the (web)app feel slow.

  Constraints:
  - Cannot use a single image for all.
  - The size of the image must fit that of the targeted device, else no splash screen will show.

  Bottom-line: we need one splash screen per resolution and orientation.

  List of devices and resolutions (MAY-2020):

  Device              Portrait size      Landscape size     Screen size        Pixel ratio
  iPhone SE            640px × 1136px    1136px ×  640px     320px ×  568px    2
  iPhone 8             750px × 1334px    1334px ×  750px     375px ×  667px    2
  iPhone 7             750px × 1334px    1334px ×  750px     375px ×  667px    2
  iPhone 6s            750px × 1334px    1334px ×  750px     375px ×  667px    2
  iPhone XR            828px × 1792px    1792px ×  828px     414px ×  896px    2
  iPhone XS           1125px × 2436px    2436px × 1125px     375px ×  812px    3
  iPhone X            1125px × 2436px    2436px × 1125px     375px ×  812px    3
  iPhone 8 Plus       1242px × 2208px    2208px × 1242px     414px ×  736px    3
  iPhone 7 Plus       1242px × 2208px    2208px × 1242px     414px ×  736px    3
  iPhone 6s Plus      1242px × 2208px    2208px × 1242px     414px ×  736px    3
  iPhone XS Max       1242px × 2688px    2688px × 1242px     414px ×  896px    3
  9.7" iPad           1536px × 2048px    2048px × 1536px     768px × 1024px    2
  7.9" iPad mini 4    1536px × 2048px    2048px × 1536px     768px × 1024px    2
  10.5" iPad Pro      1668px × 2224px    2224px × 1668px     834px × 1112px    2
  11" iPad Pro        1668px × 2388px    2388px × 1668px     834px × 1194px    2
  12.9" iPad Pro      2048px × 2732px    2732px × 2048px    1024px × 1366px    2

  Sources:
  - Device and resolutions (Portrait size, Landscape size) from
  https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/#device-screen-sizes-and-orientations

  FYI:
  - Splash screens were generated with this tool: https://github.com/onderceylan/pwa-asset-generator
  - tvOS does not come with a browser. So the largest screen to care for is an iPad.)

*/

import React, { Component } from "react"
import { Helmet } from "react-helmet"

export default class SplashScreens extends Component {
  render() {
    const data = [
      {
        width2x: '2732',
        height2x: '2048',
        width: '1024',
        height: '1366',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '1668',
        height2x: '2388',
        width: '834',
        height: '1194',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '2388',
        height2x: '1668',
        width: '834',
        height: '1194',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '1668',
        height2x: '2224',
        width: '834',
        height: '1112',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '2224',
        height2x: '1668',
        width: '834',
        height: '1112',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '1536',
        height2x: '2048',
        width: '768',
        height: '1024',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '2048',
        height2x: '1536',
        width: '768',
        height: '1024',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '1242',
        height2x: '2688',
        width: '414',
        height: '896',
        devicePixelRatio: '3',
        orientation: 'portrait',
      },
      {
        width2x: '2688',
        height2x: '1242',
        width: '414',
        height: '896',
        devicePixelRatio: '3',
        orientation: 'landscape',
      },
      {
        width2x: '1125',
        height2x: '2436',
        width: '375',
        height: '812',
        devicePixelRatio: '3',
        orientation: 'portrait',
      },
      {
        width2x: '2436',
        height2x: '1125',
        width: '375',
        height: '812',
        devicePixelRatio: '3',
        orientation: 'landscape',
      },
      {
        width2x: '828',
        height2x: '1792',
        width: '414',
        height: '896',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '1792',
        height2x: '828',
        width: '414',
        height: '896',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '1242',
        height2x: '2208',
        width: '414',
        height: '736',
        devicePixelRatio: '3',
        orientation: 'portrait',
      },
      {
        width2x: '2208',
        height2x: '1242',
        width: '414',
        height: '736',
        devicePixelRatio: '3',
        orientation: 'landscape',
      },
      {
        width2x: '750',
        height2x: '1334',
        width: '375',
        height: '667',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '1334',
        height2x: '750',
        width: '375',
        height: '667',
        devicePixelRatio: '2',
        orientation: 'landscape',
      },
      {
        width2x: '640',
        height2x: '1136',
        width: '320',
        height: '568',
        devicePixelRatio: '2',
        orientation: 'portrait',
      },
      {
        width2x: '1136',
        height2x: '640',
        width: '320',
        height: '568',
        devicePixelRatio: '3',
        orientation: 'landscape',
      },
    ]

    return (
      <Helmet>
        { data.map((screen, i) => {
          return (
            <link
              key={i}
              rel="apple-touch-startup-image"
              href={"/apple-splash-" + screen.width2x + "-" + screen.height2x + ".jpg"}
              media={
                '(device-width: ' +
                screen.width + 'px) and (device-height: ' +
                screen.height + 'px) and (-webkit-device-pixel-ratio: ' +
                screen.devicePixelRatio + ') and (orientation: ' + screen.orientation +
                ')'
              }
            />
          )
        })}
      </Helmet>
    )
  }
}
