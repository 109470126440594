import React, { Component } from "react"
import { Link as Scroll } from 'react-scroll'

export default class NavLinks extends Component {
  /* eslint-disable */
  constructor(props) {
    super(props)
    this.state = {
      activeLink: ''
    }
  }

  state = { menuShow: false }

  showMenu = () => {
    this.setState({
      menuShow: !this.state.menuShow
    })

    if (window.innerWidth < 768) {
      document.body.classList.toggle('lock-scroll');
    }
  }

  selectLink = (e) => {
    this.setState({
      activeLink: e.target.text
    })
  }

  render() {
    const data = this.props.navData

    return (
      <>
        {data.navLinks.map((link, i) => {
          return (
            <li key={i} className="nav-item">
              <Scroll
                to={link}
                onClick={(e) => {
                  this.showMenu
                  this.selectLink(e)
                }}
                className={"nav-link jd-header-nav-link jd-navbar-link" + (this.state.activeLink === link ? " active-link" : "")}
                spy={true}
                smooth={true}
                duration={500}
              >{link}</Scroll>
            </li>
          )
        })}
      </>
    )
  }
}
