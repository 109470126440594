import React, { Component } from "react"
import { Link } from "gatsby"

import LanguageSelector from "./LanguageSelector"
import NavLinks from "./NavLinks"

export default class NavBar extends Component {
  /* eslint-disable */
  constructor(props) {
    super(props)
    this.state = {
      activeLink: ''
    }
  }

  state = { menuShow: false }

  showMenu = () => {
    this.setState({
      menuShow: !this.state.menuShow
    })

    if (window.innerWidth < 768) {
      document.body.classList.toggle('lock-scroll');
    }
  }

  selectLink = (e) => {
    this.setState({
      activeLink: e.target.text
    })
  }

  render() {
    const data = this.props

    // Dinamically show/hide the NavBar component based on props being passed by the page
    let showLanguageSelectorComponent;
    data.navBarComponents.showLanguageSelector ? showLanguageSelectorComponent = <LanguageSelector />
                                               : showLanguageSelectorComponent = ''
    let showNavLinksComponent;
    data.navBarComponents.showNavLinks ? showNavLinksComponent = <NavLinks navData={data}/>
                                       : showNavLinksComponent = ''

    return (
      <nav className="navbar navbar-expand-md u-header__navbar">
        <Link to={'/' + data.locale + '/'} className="navbar-brand u-header__navbar-brand jd-header-navbar-brand">
          <img src={require('../assets/svg/logos/jd-logo-10.svg')} className="logo" alt="Logo" />
        </Link>
        <div id="jd-lang" className={"navbar-collapse py-0 " + (this.state.menuShow ? 'is-open' : '')}>
          <ul id="scrollNav" className="navbar-nav align-items-md-center jd-header-nav-link ml-lg-auto">
            {showNavLinksComponent}
            <li className="nav-item d-none d-md-inline-block pl-3 pr-0">
              <Link
                to={"/" + data.locale + "/#"}
                className="btn u-btn-primary--air btn-sm rounded-pill jd-navbar-btn-success vrlps-trigger"
              >{data.loginButtonText}</Link>
            </li>
          </ul>
        </div>
        {showLanguageSelectorComponent}
      </nav>
    )
  }
}
