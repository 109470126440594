import React, { Component } from "react"
import { Helmet } from "react-helmet"

export default class Metomic extends Component {
  render() {
    return (
      <>
        <Helmet>
          <script
            src="https://config.metomic.io/config.js?id=prj:150555c9-fcbd-4a75-a527-b6d1893866ed"
            crossorigin
            charset="utf-8"
          ></script>
          <script src="https://consent-manager.metomic.io/embed.js" crossorigin charset="utf-8"></script>
        </Helmet>
      </>
    )
  }
}
